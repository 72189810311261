import React, { Fragment } from 'react';
import { UserService } from '../services/user-service';

class Navbar extends React.Component {
  userService: UserService = UserService.getInstance();
  navigation = [
    { name: 'Osta', href: '/osta', current: true },
    { name: 'Myy', href: '/myy', current: false },
  ]

  classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  render() {
    return (
      <div>Navbar</div>
    );
  }
}

export default Navbar;