import {IUser} from '../../../common/types';	

export class UserService {
    private static instance: UserService;
    private user?: IUser;

    private constructor() {
    }

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }
}

