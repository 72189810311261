import React from "react";
import '../index.css'

interface HomeProps {

}

interface HomeState {

}

class Home extends React.Component<HomeProps, HomeState> {
    render() {
        return (
        <>
            <h1>Otsikko 1</h1>
            <h2>Otsikko 2</h2>
            <h3>Otsikko 3</h3>
            <p>Tässä normitekstiä lorem ipsum</p>
        </>
        );
    }
}

export default Home;