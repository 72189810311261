import React from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, Box } from "@mui/material";

interface DevOverlayProps {

}

interface DevOverlayState {
    dismissed: boolean;
}

class DevOverlay extends React.Component<DevOverlayProps, DevOverlayState> {
    constructor(props: DevOverlayProps) {
        super(props);
        this.state = { dismissed: false };
    }
    render() {
        if (this.state.dismissed) {
            return null;
        }

        return (
            <Dialog 
                onClose={() => this.handleClose()} 
                open={!this.state.dismissed}>
                <DialogTitle>Kehitystyö käynnissä</DialogTitle>
                <Box sx={{ p: 2 }}>
                <p>Pikakirppis on kehityksensä alkutekijöissään. Voit seurata kehitystä vierailemalla sivuilla koska tahansa. 
                    Mitkään sivun toiminnot eivät ole vielä virallisesti toiminnassa, eivätkä mitkään sivustolla näkyvät 
                    ilmoitukset ole oikeita ilmoituksia.</p>
                <p>Avaamme sivuston vapaaehtoisille testaajille kun perustoiminnot ovat toimivia.</p>
                <Button variant="contained" onClick={() => this.handleClose()}>Selvä</Button>
                </Box>
            </Dialog>
        );
    }
    handleClose() {
        this.setState({ dismissed: true });
    }
}

export default DevOverlay;