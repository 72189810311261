import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from './pages/home';
import './index.css';
import Navbar from './components/navbar';
import DevOverlay from './components/dev-overlay';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { indigo, deepPurple } from '@mui/material/colors';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([{
  path: '/',
  element: <Home />

}]);

const theme = createTheme({
    palette: {
      primary: indigo,
      secondary: indigo,
    }
});

root.render(
  <React.StrictMode>
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <Navbar />
        <DevOverlay />
        <RouterProvider
          router={router}
        />
      </ThemeProvider>
    </CssBaseline>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
